import React, { useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    Select,
    MenuItem,
    FormControl,
    SelectChangeEvent, FormControlLabel, Checkbox
} from '@mui/material';
import styled from 'styled-components';
import { useState } from 'react'
import { RegistrationFormData } from './dataTypes';
var XMLParser = require('react-xml-parser');


const Container = styled(Box)`
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
`;

const Overlay = styled(Box)`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
  justify-content: center;
  align-items: center;
`;

const Popup = styled(Box)`
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const CloseButton = styled(Typography)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const TermsLink = styled(Typography)`
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
`;

const categoryOptions = [
    { value: "technology_and_it", label: "Technology and IT" },
    { value: "finance_and_banking", label: "Finance and Banking" },
    { value: "healthcare_and_medical", label: "Healthcare and Medical" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "retail_and_consumer_goods", label: "Retail and Consumer Goods" },
    { value: "real_estate_and_construction", label: "Real Estate and Construction" },
    { value: "energy_and_utilities", label: "Energy and Utilities" },
    { value: "transportation_and_logistics", label: "Transportation and Logistics" },
    { value: "hospitality_and_tourism", label: "Hospitality and Tourism" },
    { value: "entertainment_and_media", label: "Entertainment and Media" },
    { value: "education_and_training", label: "Education and Training" },
    { value: "agriculture_and_farming", label: "Agriculture and Farming" },
    { value: "professional_services", label: "Professional Services" },
    { value: "nonprofits_and_ngos", label: "Nonprofits and NGOs" },
    { value: "government_and_public_administration", label: "Government and Public Administration" },
    { value: "mining_and_natural_resources", label: "Mining and Natural Resources" },
    { value: "telecommunications", label: "Telecommunications" },
    { value: "biotechnology_and_pharmaceuticals", label: "Biotechnology and Pharmaceuticals" },
    { value: "automotive", label: "Automotive" },
    { value: "environmental_and_green_energy", label: "Environmental and Green Energy" }
];
const RegistrationForm: React.FC = () => {
    const [isPopupOpen, setPopupOpen] = React.useState(false);
    const [formData, setFormData] = useState<RegistrationFormData>({
        fullName: '',
        companyName: '',
        email: '',
        businessId: '',
        phoneNumber: '',
        jobPosition: '',
        businessCategory: '',
        howDidYouLearnAboutUs: '',
        termsAccepted: false,
      });
    // const setCompanyInformation = (data: any)  => {
    //     setFormData({
    //         ...formData
    //     })
    // }

    useEffect(() => {
        fetch(`https://wwwinfo.mfcr.cz/cgi-bin/ares/darv_std.cgi?ico=${formData.businessId}`)
            .then(res => res.text())
            .then(data => {
                var xml = new XMLParser().parse.parseFromString(data);
                console.log(xml)
            })
            .catch(err => console.log(err));
    }, [formData.businessId])


    // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setFormData({
    //       ...formData,
    //       [e.target.name]: e.target.value,
    //     });
    //   };
    
      const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // const response = await postRequest('https://your-api-url.com', formData);

        console.log("response");
      }

    const openPopup = () => setPopupOpen(true);
    const closePopup = () => setPopupOpen(false);

    const handleCategoryChange = (e: SelectChangeEvent<HTMLInputElement>) => {
    };

    return (
        <>
            <Container>
                <img src="http://nanolabs.cz/images/logo.png" alt="Logo" />
                <Typography variant="h2" align="center">Registration</Typography>
                <form onSubmit={handleSubmit} >
                    <TextField id="full-name-input" label="* Full name" fullWidth margin="normal" required value={formData.fullName}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, fullName: event.target.value });
                               }}/>
                    <TextField id="company-name-input"  label="* Company Name" fullWidth margin="normal" required value={formData.companyName}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, companyName: event.target.value });
                               }}/>
                    <TextField id="email-input"  label="* Email" type="email" fullWidth margin="normal" required value={formData.email}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, email: event.target.value });
                               }}/>
                    <TextField id="business-id-input"  label="* Business ID (IČO)" fullWidth margin="normal" required value={formData.businessId}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, businessId: event.target.value });
                               }}/>
                    <TextField id="phone-number-input"  label="* Phone number" fullWidth margin="normal" required value={formData.phoneNumber}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, phoneNumber: event.target.value });
                               }}/>
                    <TextField id="job-position-input"  label="* Job position" fullWidth margin="normal" required value={formData.jobPosition}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, jobPosition: event.target.value });
                               }}/>

                    <FormControl fullWidth margin="normal" required>
                        <Select
                            onChange={handleCategoryChange}
                            name="businessCategory"
                        >
                            {categoryOptions.map(({value, label}) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <TextField id="reference-input"  label="How did you learn about us" fullWidth margin="normal" value={formData.howDidYouLearnAboutUs}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, howDidYouLearnAboutUs: event.target.value });
                               }}/>

                    <FormControlLabel
                        control={<Checkbox required value={formData.termsAccepted}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   setFormData({ ...formData, termsAccepted: !!event.target.value });
                               }}/>}
                        id="terms-accepted-input"  label="I have read and understood the"
                    />
                    <TermsLink onClick={openPopup}>terms and conditions</TermsLink>
                    <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: 16 }}>
                        Register
                    </Button>
                </form>
            </Container>

            {isPopupOpen && (
                <Overlay onClick={closePopup}>
                    <Popup onClick={(e) => e.stopPropagation()}>
                        <CloseButton onClick={closePopup}>&times;</CloseButton>
                        <Typography variant="h2">Terms and Conditions</Typography>
                        {/* Terms and conditions content goes here */}
                    </Popup>
                </Overlay>
            )}
        </>
    );
};

export default RegistrationForm;